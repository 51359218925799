import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { isAuthenticated } from '../hooks/CookiesHook'
import { getLocalStorage } from '../utils/helper'

// Lazy load pages
const PageNotFound = lazy(() => import('../pages/PageNotFound'))
const Login = lazy(() => import('../pages/Login'))
const OTPVerification = lazy(() => import('../pages/OTPVerification'))
const SelectWorkspace = lazy(() => import('../pages/SelectWorkspace'))
const ChangeWorkspace = lazy(() => import('../pages/ChangeWorkspace'))
// Dashboard
const Dashboard = lazy(() => import('../pages/Dashboard'))
const StatisticsCaptureDashboard = lazy(() => import('../pages/Dashboard/StatisticsCaptureDashboard'))
const IncidentDashboard = lazy(() => import('../pages/Dashboard/IncidentDashboard'))
const InspectionDashboard = lazy(() => import('../pages/Dashboard/InspectionDashboard'))
// User Management
const UserRole = lazy(() => import('../pages/UserManagement/UserRole'))
const UserListing = lazy(() => import('../pages/UserManagement/UserListing'))
const AddUserRole = lazy(() => import('../pages/UserManagement/AddUserRole'))
const AddUser = lazy(() => import('../pages/UserManagement/AddUser'))
// Project Management
const ProjectManagement = lazy(() => import('../pages/ProjectManagement'))
const AddProject = lazy(() => import('../pages/ProjectManagement/AddProject'))
const ProjectWorkflow = lazy(() => import('../pages/ProjectManagement/ProjectWorkflow'))
const ProjectUsers = lazy(() => import('../pages/ProjectManagement/ProjectUsers'))
const AddUserToProject = lazy(() => import('../pages/ProjectManagement/ProjectUsers/AddUserToProject'))
const ProjectRoles = lazy(() => import('../pages/ProjectManagement/ProjectRoles'))
const AddRoleToProject = lazy(() => import('../pages/ProjectManagement/ProjectRoles/AddRoleToProject'))
// Task Management
const TaskManagement = lazy(() => import('../pages/TaskManagement'))
const Observations = lazy(() => import('../pages/TaskManagement/Observations'))
const Incidents = lazy(() => import('../pages/TaskManagement/Incidents'))
const ObservationDetails = lazy(() => import('../pages/TaskManagement/Observations/ObservationDetails'))
const IncidentDetails = lazy(() => import('../pages/TaskManagement/Incidents/IncidentDetails'))
const Inspections = lazy(() => import('../pages/TaskManagement/Inspections'))
const NonConformance = lazy(() => import('../pages/TaskManagement/Inspections/NonConformance'))
const NonConformanceDetails = lazy(() => import('../pages/TaskManagement/Inspections/NonConformance/NonConformanceDetails'))
const InspectionDetails = lazy(() => import('../pages/TaskManagement/Inspections/InspectionDetails'))
const Schedule = lazy(() => import('../pages/TaskManagement/Inspections/Schedule'))
const AddInspectionSchedule = lazy(() => import('../pages/TaskManagement/Inspections/Schedule/AddInspectionSchedule'))
const WorkPermits = lazy(() => import('../pages/TaskManagement/WorkPermits'))
const Trainings = lazy(() => import('../pages/TaskManagement/Trainings'))
// statistics Capture
const StatisticsCapture = lazy(() => import('../pages/ProjectManagement/StatisticsCapture/Schedule'))
const AddSchedule = lazy(() => import('../pages/ProjectManagement/StatisticsCapture/Schedule/AddSchedule'))
const StatisticsCaptureReport = lazy(() => import('../pages/ProjectManagement/StatisticsCapture/StatisticsCaptureReport'))
const EditReport = lazy(() => import('../pages/ProjectManagement/StatisticsCapture/StatisticsCaptureReport/EditReport'))

// Copilots pages
const Copilots = lazy(() => import('../pages/Copilots'))

// Masters pages
const Division = lazy(() => import('../pages/Masters/Division'))
const HazardType = lazy(() => import('../pages/Masters/HazardType'))
const RootCause = lazy(() => import('../pages/Masters/RootCause'))
const Severity = lazy(() => import('../pages/Masters/Severity'))
const ObservationStatus = lazy(() => import('../pages/Masters/ObservationStatus'))
const StatisticsCaptureStatus = lazy(() => import('../pages/Masters/StatisticsCaptureStatus'))
const StatisticsCaptureInputs = lazy(() => import('../pages/Masters/StatisticsCaptureInputs'))
const IncidentStatus = lazy(() => import('../pages/Masters/IncidentStatus'))
const IncidentCategory = lazy(() => import('../pages/Masters/IncidentCategory'))
const IncidentSeverity = lazy(() => import('../pages/Masters/IncidentSeverity'))
const InspectionStatus = lazy(() => import('../pages/Masters/InspectionStatus'))
const InspectionScheduleStatus = lazy(() => import('../pages/Masters/InspectionScheduleStatus'))
const InspectionNonConformanceStatus = lazy(() => import('../pages/Masters/InspectionNonConformanceStatus'))
const InspectionSubCategory = lazy(() => import('../pages/Masters/InspectionSubCategory'))
const InspectionTopic = lazy(() => import('../pages/Masters/InspectionTopic'))
const InspectionQuestions = lazy(() => import('../pages/Masters/InspectionQuestions'))

// Lazy load components
const DashboardLayout = lazy(() => import('../components/UI/Layouts/DashboardLayout'))
const LRFLayout = lazy(() => import('../components/UI/Layouts/LRFLayout'))
const ProjectLayout = lazy(() => import('../components/UI/Layouts/ProjectLayout'))
const TasksLayout = lazy(() => import('../components/UI/Layouts/TasksLayout'))
const InspectionLayout = lazy(() => import('../components/UI/Layouts/InspectionLayout'))
const TabsLayout = lazy(() => import('../components/UI/Layouts/TabsLayout'))
const StatisticsCaptureLayout = lazy(() => import('../components/UI/Layouts/StatisticsCaptureLayout'))
const UserManagementLayout = lazy(() => import('../components/UI/Layouts/UserManagementLayout'))
const ComingSoon = lazy(() => import('../components/ComingSoon'))

// if already authenticated and try to access login page, redirect to dashboard
const PublicRoute = ({ children }: any) => {
  const permission_details = getLocalStorage('permissions') ? JSON.parse(`${getLocalStorage('permissions')}`) : []

  let redirectPath = '/dashboard'
  const paths = [
    { path: '/dashboard', slug: 'dashboard' },
    { path: '/reports', slug: 'reports' },
    { path: '/user-management/user-role', slug: 'user-management' },
    { path: '/user-management/user-role', slug: 'user' },
    { path: '/project-management', slug: 'project-management' },
    { path: '/project-management', slug: 'project-list' },
  ] as any
  if (!permission_details?.find((p: any) => p?.slug === 'observations')) {
    const firstSlug = permission_details[0]?.slug
    if (paths?.find((p: any) => p?.slug === firstSlug) && firstSlug) {
      redirectPath = paths?.find((p: any) => p?.slug === firstSlug)?.path
    }
  }

  return isAuthenticated() ? <Navigate to={redirectPath} /> : children
}

// if not authenticated and try to access protected page, redirect to login
const ProtectedRoute = ({ children }: any) => {
  return isAuthenticated() ? children : <Navigate to="/login" />
}

const wrapRoutesWithProtectedRoute = (routes: any) => {
  // Map over the routes array
  return routes.map((route: any) => {
    // Check if the route has children
    if (route.children) {
      // If it has children, recursively wrap the children with protected route
      const children = wrapRoutesWithProtectedRoute(route.children)
      return {
        ...route,
        children,
      }
    } else {
      // If the route does not have children, determine whether to wrap it with protected or public route
      return {
        ...route,
        element: route?.private ? <ProtectedRoute key={route.path}>{route.element}</ProtectedRoute> : <PublicRoute key={route.path}>{route.element}</PublicRoute>,
      }
    }
  })
}

export const routes = [
  ...wrapRoutesWithProtectedRoute([
    {
      path: '',
      private: false,
      element: (
        <LRFLayout>
          <Login />
        </LRFLayout>
      ),
    },
    {
      path: 'login',
      private: false,
      element: (
        <LRFLayout>
          <Login />
        </LRFLayout>
      ),
    },
    {
      path: 'otp-verification',
      private: false,
      element: (
        <LRFLayout>
          <OTPVerification />
        </LRFLayout>
      ),
    },
    {
      path: 'select-workspace',
      private: false,
      element: (
        <LRFLayout>
          <SelectWorkspace />
        </LRFLayout>
      ),
    },
    {
      path: 'change-workspace',
      private: true,
      element: (
        <DashboardLayout>
          <ChangeWorkspace />
        </DashboardLayout>
      ),
    },
    {
      path: 'dashboard',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Dashboard', redirect: '/dashboard' },
            { title: 'Observations', redirect: '/dashboard' },
          ]}
        >
          <Dashboard />
        </DashboardLayout>
      ),
    },
    {
      path: 'dashboard/statistics-capture',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Dashboard', redirect: '/dashboard' },
            { title: 'Statistics Capture', redirect: '/dashboard' },
          ]}
        >
          <StatisticsCaptureDashboard />
        </DashboardLayout>
      ),
    },
    {
      path: 'dashboard/incident',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Dashboard', redirect: '/dashboard' },
            { title: 'Incidents', redirect: '/dashboard' },
          ]}
        >
          <IncidentDashboard />
        </DashboardLayout>
      ),
    },
    {
      path: 'dashboard/inspection',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Dashboard', redirect: '/dashboard' },
            { title: 'Inspections', redirect: '/dashboard' },
          ]}
        >
          <InspectionDashboard />
        </DashboardLayout>
      ),
    },
    {
      path: 'reports',
      private: true,
      element: (
        <DashboardLayout>
          <div className="h-full p-8">
            <ComingSoon pageName="Statistics Capture" />
          </div>
        </DashboardLayout>
      ),
    },
    {
      path: 'user-management/user-role',
      private: true,
      element: (
        <UserManagementLayout
          defaultIndex={0}
          breadcrumbData={[
            { title: 'User Management', redirect: '/user-management/user-role' },
            { title: 'User Role', redirect: '/user-management/user-role' },
          ]}
        >
          <UserRole />
        </UserManagementLayout>
      ),
    },
    {
      path: 'user-management/user-role/:id',
      private: true,
      element: (
        <UserManagementLayout
          defaultIndex={0}
          breadcrumbData={[
            { title: 'User Management', redirect: '/user-management/user-role' },
            { title: 'User Role', redirect: '/user-management/user-role' },
          ]}
        >
          <AddUserRole />
        </UserManagementLayout>
      ),
    },
    {
      path: 'user-management/users',
      private: true,
      element: (
        <UserManagementLayout
          defaultIndex={1}
          breadcrumbData={[
            { title: 'User Management', redirect: '/user-management/users' },
            { title: 'Users', redirect: '/user-management/users' },
          ]}
        >
          <UserListing />
        </UserManagementLayout>
      ),
    },
    {
      path: 'user-management/users/:id',
      private: true,
      element: (
        <UserManagementLayout
          defaultIndex={1}
          breadcrumbData={[
            { title: 'User Management', redirect: '/user-management/users' },
            { title: 'Users', redirect: '/user-management/users' },
          ]}
        >
          <AddUser />
        </UserManagementLayout>
      ),
    },
    //Project Routes
    {
      path: 'project-management',
      private: true,
      element: (
        <DashboardLayout>
          <ProjectManagement />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/details',
      private: true,
      element: (
        <ProjectLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Details', redirect: '/project-management/:name/:id/details' },
          ]}
        >
          <AddProject mode={'detail'} />
        </ProjectLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/workflow',
      private: true,
      element: (
        <ProjectLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Workflow', redirect: '/project-management/:name/:id/details' },
          ]}
        >
          <ProjectWorkflow />
        </ProjectLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/location',
      private: true,
      element: (
        <ProjectLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Location', redirect: '/project-management/:name/:id/details' },
          ]}
        >
          <div className="h-full p-8">
            <ComingSoon pageName="Location" />
          </div>
        </ProjectLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/users',
      private: true,
      element: (
        <ProjectLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Users', redirect: '/project-management/:name/:id/details' },
          ]}
        >
          <ProjectUsers />
        </ProjectLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/users/add',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Users', redirect: '/project-management/:name/:id/users' },
            { title: 'Add', redirect: '/project-management/:name/:id/users' },
          ]}
        >
          <AddUserToProject />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/users/:subName/:subId',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Users', redirect: '/project-management/:name/:id/users' },
            { title: ':subName', redirect: '/project-management/:name/:id/users' },
          ]}
        >
          <AddUserToProject />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/roles',
      private: true,
      element: (
        <ProjectLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Roles', redirect: '/project-management/:name/:id/details' },
          ]}
        >
          <ProjectRoles />
        </ProjectLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/roles/add',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Roles', redirect: '/project-management/:name/:id/roles' },
            { title: 'Add', redirect: '/project-management/:name/:id/roles' },
          ]}
        >
          <AddRoleToProject />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/roles/:subName/:subId',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Roles', redirect: '/project-management/:name/:id/roles' },
            { title: ':subName', redirect: '/project-management/:name/:id/roles' },
          ]}
        >
          <AddRoleToProject />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager',
      private: true,
      element: (
        <TasksLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
          ]}
        >
          <Observations />
        </TasksLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/observations',
      private: true,
      element: (
        <TasksLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Observations', redirect: '/project-management/:name/:id/task-manager/observations' },
          ]}
        >
          <Observations />
        </TasksLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/incidents',
      private: true,
      element: (
        <TasksLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Incidents', redirect: '/project-management/:name/:id/task-manager/incidents' },
          ]}
        >
          <Incidents />
        </TasksLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/incident/:subName/:subId',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Incidents', redirect: '/project-management/:name/:id/task-manager/incidents' },
            { title: ':subName', redirect: '/project-management/:name/:id/task-manager/incidents' },
          ]}
        >
          <IncidentDetails />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/observations/:subName/:subId',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Observations', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: ':subName', redirect: '/project-management/:name/:id/task-manager/observations' },
          ]}
        >
          <ObservationDetails />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/inspections/inspections',
      private: true,
      element: (
        <InspectionLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Inspections', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
            { title: 'Inspections List', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
          ]}
        >
          <Inspections />
        </InspectionLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/inspections/inspections/:subName/:subId',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Inspections', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
            { title: 'Inspections List', redirect: '/project-management/:name/:id/task-manager/inspections/inspections' },
            { title: ':subName', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
          ]}
        >
          <InspectionDetails />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/inspections/schedule',
      private: true,
      element: (
        <InspectionLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Inspections', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
            { title: 'Schedule', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
          ]}
        >
          <Schedule />
        </InspectionLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/inspections/schedule/add',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Inspections', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
            { title: 'Schedule', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
            { title: 'Add Schedule', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
          ]}
        >
          <AddInspectionSchedule />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/inspections/schedule/:subName/:subId',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Inspections', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
            { title: 'Schedule', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
            { title: ':subName', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
          ]}
        >
          <AddInspectionSchedule />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/inspections/non-conformance',
      private: true,
      element: (
        <InspectionLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Inspections', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
            { title: 'Non-Conformance', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
          ]}
        >
          <NonConformance />
        </InspectionLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/task-manager/inspections/non-conformance/:subName/:subId',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Task Manager', redirect: '/project-management/:name/:id/task-manager/observations' },
            { title: 'Inspections', redirect: '/project-management/:name/:id/task-manager/inspections/schedule' },
            { title: 'Non-Conformance', redirect: '/project-management/:name/:id/task-manager/inspections/non-conformance' },
            { title: ':subName', redirect: '/project-management/:name/:id/task-manager/inspections/inspections' },
          ]}
        >
          <NonConformanceDetails />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/statistics-capture/schedule',
      private: true,
      element: (
        <StatisticsCaptureLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Statistics Capture', redirect: '/project-management/:name/:id/statistics-capture/schedule' },
          ]}
        >
          <StatisticsCapture />
        </StatisticsCaptureLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/statistics-capture/schedule/add',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Statistics Capture', redirect: '/project-management/:name/:id/statistics-capture/schedule' },
            { title: 'Add Schedule', redirect: '/project-management/:name/:id/statistics-capture/schedule/add' },
          ]}
        >
          <AddSchedule />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/statistics-capture/schedule/:subName/:subId',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Statistics Capture', redirect: '/project-management/:name/:id/statistics-capture/schedule' },
            { title: ':subName', redirect: '/project-management/:name/:id/statistics-capture/schedule/add' },
          ]}
        >
          <AddSchedule />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/statistics-capture/report',
      private: true,
      element: (
        <StatisticsCaptureLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Statistics Capture', redirect: '/project-management/:name/:id/statistics-capture/schedule' },
          ]}
        >
          <StatisticsCaptureReport />
        </StatisticsCaptureLayout>
      ),
    },
    {
      path: 'project-management/:name/:id/statistics-capture/report/:subName/:subId',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Project Management', redirect: '/project-management' },
            { title: ':name', redirect: '/project-management/:name/:id/details' },
            { title: 'Statistics Capture', redirect: '/project-management/:name/:id/statistics-capture/report' },
            { title: 'Edit Report', redirect: '/project-management/:name/:id/statistics-capture/report' },
            { title: ':subName', redirect: '/project-management/:name/:id/statistics-capture/report' },
          ]}
        >
          <EditReport />
        </DashboardLayout>
      ),
    },
    {
      path: 'project-management/add',
      private: true,
      element: (
        <DashboardLayout>
          <AddProject />
        </DashboardLayout>
      ),
    },
    {
      path: 'copilots',
      private: true,
      element: (
        <DashboardLayout>
          <Copilots />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Division', redirect: '/masters' },
          ]}
        >
          <Division />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/hazard-type',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Hazard Type', redirect: '/masters' },
          ]}
        >
          <HazardType />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/root-cause',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Root Cause', redirect: '/masters' },
          ]}
        >
          <RootCause />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/severity',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Severity', redirect: '/masters' },
          ]}
        >
          <Severity />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/observation-status',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Observation Status', redirect: '/masters' },
          ]}
        >
          <ObservationStatus />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/statistics-capture-status',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Statistics Capture Status', redirect: '/masters' },
          ]}
        >
          <StatisticsCaptureStatus />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/statistics-capture-inputs',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Statistics Capture Inputs', redirect: '/masters' },
          ]}
        >
          <StatisticsCaptureInputs />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/incident-status',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Incident Status', redirect: '/masters' },
          ]}
        >
          <IncidentStatus />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/incident-category',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Incident Category', redirect: '/masters' },
          ]}
        >
          <IncidentCategory />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/incident-severity',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Incident Severity', redirect: '/masters' },
          ]}
        >
          <IncidentSeverity />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/inspection-status',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Inspection Status', redirect: '/masters' },
          ]}
        >
          <InspectionStatus />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/inspection-schedule-status',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Inspection Schedule Status', redirect: '/masters' },
          ]}
        >
          <InspectionScheduleStatus />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/inspection-non-conformance-status',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Inspection Non Conformance Status', redirect: '/masters' },
          ]}
        >
          <InspectionNonConformanceStatus />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/inspection-sub-category',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Inspection Sub Category', redirect: '/masters' },
          ]}
        >
          <InspectionSubCategory />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/inspection-topic',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Inspection Topic', redirect: '/masters' },
          ]}
        >
          <InspectionTopic />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters/inspection-questions',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Masters', redirect: '/masters' },
            { title: 'Inspection Questions', redirect: '/masters' },
          ]}
        >
          <InspectionQuestions />
        </DashboardLayout>
      ),
    },
  ]),
  {
    path: '*',
    element: <PageNotFound />,
  },
]
